<template>
  <div v-if="$role(['director', 'service_advisor', 'head_operation'], $store.state.user.role)">
    <v-row no-gutters>
      <v-col cols="12" class="d-flex jutify-space-between col-sm-2 py-4">
        <v-card :class="$role(['service_advisor', 'head_operation'], $store.state.user.role) ? 'primary' : 'grey'" class="d-flex align-center justify-center px-4 fill-width caption font-weight-bold py-2" dark outlined @click.prevent="$role(['service_advisor', 'head_operation'], $store.state.user.role) ? VEHICLE_SHOW() : null">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          New Vehicle
        </v-card>
      </v-col>
      <v-col cols="12" class="col-sm-10 white">
        <div class="px-4 pt-4 pb-0 pb-sm-4">
          <v-text-field
            v-model="tableSearch"
            outlined
            dense
            hide-details
            placeholder="Search Vehicle ..."
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="12" class="pt-4">
        <v-data-table
          dense
          :headers="headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div>
              #{{ item.id }}
            </div>
          </template>
          <template v-slot:[`item.vehicle_brand`]="{ item }">
            <div class="py-2">
              <div>
                {{ item.vehicle_brand }} {{ item.vehicle_type }}
                <div>
                  {{ item.vehicle_year }}
                </div>
              </div>
            </div>
          </template>
          <template v-slot:[`item.vehicle_plat`]="{ item }">
            <div class="py-2">
              <div>
                <strong class="text-uppercase">{{ item.vehicle_plat }}</strong>
                <div class="d-flex align-center">
                  {{ parseInt(item.customer) ? item.customer_data : 'Not Assigned' }}
                  <v-chip v-if="$role(['service_advisor', 'head_operation'], $store.state.user.role)" :color="$role(['service_advisor', 'head_operation'], $store.state.user.role) ? 'primary' : 'grey'" small outlined class="ml-2" @click.prevent="$role(['service_advisor', 'head_operation'], $store.state.user.role) ? VEHICLE_REGISTER_SHOW(item) : false">
                    <v-icon class="mr-1" x-small>
                      mdi-account-switch
                    </v-icon>
                    Update
                  </v-chip>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:[`item.service_count`]="{ item }">
            <div class="py-2 text-right" style="min-width:75px;">
              <div class="d-flex align-center">
                <v-icon class="mr-1" x-small>
                  mdi-tools
                </v-icon>
                {{ (parseInt(item.service_count) || 0) }}x
              </div>
            </div>
          </template>
          <template v-slot:[`item.last_service`]="{ item }">
            <div class="py-2 text-right">
              <div class="d-flex align-center">
                {{ item.last_service || '-' }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.customer_data`]="{ item }">
            <div>
              <div :class="parseInt(item.customer) ? '' : 'grey--text'">
                {{ parseInt(item.customer) ? item.customer_data : 'Not Assigned' }}
              </div>
              <div class="caption">
                Note: {{ item.notes || '-' }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn v-if="$role(['service_advisor', 'head_operation'], $store.state.user.role)" :color="$role(['service_advisor', 'head_operation'], $store.state.user.role) ? 'orange' : ''" depressed small dark class="rounded text-capitalize" @click.prevent="$role(['service_advisor', 'head_operation'], $store.state.user.role) ? VEHICLE_SHOW(item) : false">
                <v-icon small class="mr-2">
                  mdi-information-outline
                </v-icon>
                Detail
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogVehicle.show"
      persistent
      scrollable
      max-width="375"
    >
      <v-card v-if="dialogVehicle.data" >
        <v-card-title class="body-1 justify-center">
          {{ dialogVehicle.data.id ? 'Update Vehicle' : 'Create New Vehicle' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 300px;">
          <v-text-field
            v-model="dialogVehicle.data.vehicle_plat"
            outlined
            dense
            hide-details
            label="Vehicle Plat Number"
            class="mb-4 rounded-0"
          />
          <v-autocomplete
            v-model="dialogVehicle.data.vehicle_brand"
            :items="$store.state.product.categories"
            item-text="name"
            item-value="name"
            outlined
            dense
            hide-details
            label="Select Brand"
            class="mb-4"
            :disabled="!$role(['manager', 'warehouse'], $store.state.user.role)"
          >
            <template v-slot:item="data">
              <template>
                <v-list-item-avatar>
                  <img :src="data.item.logo">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name" />
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
          <v-text-field
            v-model="dialogVehicle.data.vehicle_type"
            outlined
            dense
            hide-details
            label="Vehicle Type / Name"
            class="mb-4 rounded-0"
          />
          <v-text-field
            v-model="dialogVehicle.data.vehicle_year"
            outlined
            dense
            hide-details
            label="Vehicle Production Year"
            class="mb-4 rounded-0"
            @keypress="$NumOnly"
          />
          <v-textarea
            v-model="dialogVehicle.data.notes"
            outlined
            dense
            hide-details
            label="Notes"
            class="mb-4 rounded-0"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="dialogVehicle.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="VEHICLE_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogVehicleRegister.show"
      persistent
      scrollable
      max-width="375"
    >
      <v-card>
        <v-card-title class="body-1 justify-center">
          Update Vehicle Owner
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 300px;">
          <v-autocomplete
            v-model="dialogVehicleRegister.customer"
            :search-input.sync="dialogVehicleRegister.query"
            :items="dialogVehicleRegister.result"
            item-text="detailname"
            item-value="id"
            outlined
            dense
            hide-details
            clearable
            autocomplete="none"
            prepend-icon="mdi-account-convert-outline"
            class="rounded-0 fill-width"
            label="Search Customer"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="dialogVehicleRegister.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="VEHICLE_REGISTER_PROCESS()"
          >
            Process
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
let searchInterval = null
let searchTimeout = null
export default {
  name: 'VehicleData',
  data: () => ({
    headers: [
      {
        text: 'ID',
        value: 'id',
        sortable: false
      },
      { text: 'Vehicle', value: 'vehicle_brand', sortable: true },
      { text: 'Register', value: 'vehicle_plat', sortable: true },
      { text: 'Services', value: 'service_count', sortable: true },
      { text: 'Last Service', value: 'last_service', sortable: true },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    tableSearch: '',
    tableLoading: false,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 25,
    tableSearchStatus: null,
    options: {},
    dialogVehicle: {
      show: false,
      data: null
    },
    dialogVehicleRegister: {
      show: false,
      id: 0,
      customer: 0,
      vehicle_plat: 0,
      query: '',
      result: []
    }
  }),
  computed: {
  },
  watch: {
    'dialogVehicleRegister.query' (v) {
      if (v) {
        this.dialogVehicleRegister.result = []
        if (searchTimeout) {
          clearTimeout(searchTimeout)
        }
        searchTimeout = setTimeout(() => {
          this.CUSTOMER_GET(v)
        }, 300)
      }
    },
    tableSearch: function (q) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.VEHICLE_GET(q)
      }, 300)
    },
    options: {
      handler () {
        this.VEHICLE_GET(this.tableSearch)
      },
      deep: true
    },
    tableSearchStatus () {
      this.VEHICLE_GET(this.tableSearch)
    }
  },
  created () {
    // this.VEHICLE_GET()
  },
  methods: {
    VEHICLE_REGISTER_SHOW (vehicle) {
      if (vehicle) {
        this.dialogVehicleRegister.id = parseInt(vehicle.id)
        this.dialogVehicleRegister.vehicle_plat = vehicle.vehicle_plat
        this.dialogVehicleRegister.customer = parseInt(vehicle.customer) || 0
        this.dialogVehicleRegister.show = true
      } else {
        this.$store.dispatch('TOAST', { show: true, message: 'No vehicle selected!' })
        return false
      }
    },
    VEHICLE_REGISTER_PROCESS () {
      if (!parseInt(this.dialogVehicleRegister.id)) {
        this.$store.dispatch('TOAST', { show: true, message: 'No vehicle selected!' })
        return false
      }
      if (!parseInt(this.dialogVehicleRegister.customer)) {
        this.$store.dispatch('TOAST', { show: true, message: 'No customer selected!' })
        return false
      }
      if (!confirm('Are you sure ?')) {
        return false
      }
      this.$store.dispatch('customer/VEHICLE_PROCESS', {
        id: this.dialogVehicleRegister.id,
        customer: this.dialogVehicleRegister.customer,
        vehicle_plat: this.dialogVehicleRegister.vehicle_plat
      })
        .then((res) => {
          if (res.status) {
            this.VEHICLE_GET(this.tableSearch)
            this.dialogVehicleRegister.show = false
            this.$store.dispatch('TOAST', { show: true, message: 'Updated' })
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
          }
        })
    },
    CUSTOMER_GET (q) {
      this.$store.dispatch('customer/CUSTOMER_GET', ('?q=' + (q || ''))).then((res) => {
        if (res.status) {
          this.dialogVehicleRegister.result = res.data.data || []
        } else {
          this.dialogVehicleRegister.result = []
        }
      })
    },
    VEHICLE_SHOW (vehicle) {
      if (vehicle) {
        this.dialogVehicle.data = Object.assign({}, vehicle)
      } else {
        this.dialogVehicle.data = {
          id: null,
          customer: 0,
          vehicle_brand: '',
          vehicle_type: '',
          vehicle_year: '',
          vehicle_plat: '',
          vehicle_rangka: '',
          vehicle_mesin: '',
          notes: ''
        }
      }
      this.dialogVehicle.show = true
    },
    VEHICLE_GET (q) {
      this.tableLoading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const query = '?c=' + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy ? (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '') : '')
      this.$store.dispatch('customer/VEHICLE_GET', query + (q ? ('&q=' + q) : '')).then((res) => {
        if (res.status) {
          this.table = res.data.data.data
          this.tableTotal = parseInt(res.data.data.total) || 0
        } else {
          this.table = []
          this.tableTotal = 0
        }
        this.tableLoading = false
      })
    },
    VEHICLE_PROCESS () {
      const processedVehicle = Object.assign({}, this.dialogVehicle.data)
      if (!processedVehicle.vehicle_brand) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid vehicle brand!' })
        return false
      }
      if (!processedVehicle.vehicle_type) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid vehicle type!' })
        return false
      }
      if (parseInt(processedVehicle.vehicle_year) < 1890 || parseInt(processedVehicle.vehicle_year) > parseInt((new Date()).getFullYear())) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid vehicle production year!' })
        return false
      }
      if (!processedVehicle.vehicle_plat) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid vehicle plat number!' })
        return false
      } else {
        processedVehicle.vehicle_plat = processedVehicle.vehicle_plat.toUpperCase().split(' ').join('')
      }
      this.$store.dispatch('customer/VEHICLE_PROCESS', processedVehicle)
        .then((res) => {
          if (res.status) {
            this.VEHICLE_GET(this.tableSearch)
            this.dialogVehicle.show = false
            this.$store.dispatch('TOAST', { show: true, message: processedVehicle.id ? 'Updated' : 'Created!' })
          } else {
            if (res.data) {
              if (res.data.response) {
                if (res.data.response.data.message) {
                  this.$store.dispatch('TOAST', { show: true, message: res.data.response.data.message || 'Something went wrong!' })
                  return false
                }
              }
            }
            this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
          }
        })
    }
  }
}
</script>
